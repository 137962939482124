.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: whitesmoke; /* #282c34; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: steelblue;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image {
  position: relative;
}

.image__img {
  display: block;
  width: 100%;
}

.image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.image__overlay--blur {
  backdrop-filter: blur(5px);
}

.image__overlay--primary {
  background: whitesmoke;
}

.image__overlay > * {
  transform: translateY(0px);
  transition: transform 0.25s;
}

.image__overlay:hover {
  opacity: 1;
}

.image__overlay:hover > * {
  transform: translateY(0);
}

.image__title {
  font-size: 3.5vw;
  font-weight: bold;
  color: black;
  font-style: italic;
}

.image__description {
  font-size: 2.5vw;
  margin-top: 0.25em;
  color: black;
}

.image__description__2 {
  font-size: 1em;
  margin-top: 0.25em;
  color: black;
}

.smallHeaderText {
  color: darkgray;
  font-size: calc(10px + 1.2vmin);
}

.margin-Right {
  margin-right: 20px;
}

.displayRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
